// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/sentry/tunnel";globalThis["SENTRY_RELEASE"] = {"id":"be64fede5e887ac9c2f2e4cad55586f4427bf972"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import { browserTracingIntegration, init, replayIntegration } from '@sentry/nextjs';

import { config } from '@endaoment-frontend/config';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

init({
  dsn: SENTRY_DSN || 'https://65c1e3f584b4e4dd1d80ff99ed68e27c@o1285899.ingest.us.sentry.io/4508213162278912',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    browserTracingIntegration(),
    replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [config.baseUrls.api],
    }),
  ],

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: process.env.NEXT_PUBLIC_ENV,

  // If tracePropagationTargets is not provided, trace data is attached to every outgoing request from the instrumented client.
  tracePropagationTargets: undefined,

  ignoreErrors: [
    /Loading chunk \d+ failed/,
    /Loading CSS chunk \d+ failed/,
    'Invariant: attempted to hard navigate to the same URL',
    'WebSocket connection failed for host: wss://relay.walletconnect.org',
    'Load failed',
    'This Suspense boundary received an update before it finished hydrating. This caused the boundary to switch to client rendering. The usual way to fix this is to wrap the original update in startTransition.',
  ],
});
