import { defaults } from '@endaoment-frontend/config';
import { addressSchema, type Address, type AdminAccountName } from '@endaoment-frontend/types';
import { capitalize, equalAddress } from '@endaoment-frontend/utils';

export const isAdministrativeAccount = (address?: string, accountName?: AdminAccountName): boolean => {
  if (!address) return false;
  const addressParse = addressSchema.safeParse(address);
  if (!addressParse.success) return false;

  if (!accountName)
    return [
      defaults.roles.accountant,
      defaults.roles.reviewer,
      defaults.roles.reviewer,
      defaults.roles.keeper,
      defaults.roles.maintainer,
    ]
      .flat()
      .filter(Boolean)
      .some(acc => equalAddress(acc, addressParse.data));

  return defaults.roles[accountName].some(acc => equalAddress(acc, addressParse.data));
};

export const getEndaomentAccountName = (address?: Address | '' | null) => {
  if (!address) return '';

  for (const [name, accountArr] of Object.entries(defaults.roles)) {
    for (const acc of accountArr) {
      if (equalAddress(acc, address)) return capitalize(name) as Capitalize<AdminAccountName>;
    }
  }

  return '';
};
